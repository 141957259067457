import { Heading, Text } from '@legalshield/adonis-ux-framework';
import { useCalculator } from 'contexts/CalculatorContext/useCalculator';
import { Fragment } from 'react';
import CategoryProduct from 'components/CategoryProduct';
import './index.scss';

const Categories = () => {
  const { products } = useCalculator();
  return (
    <div className="categories-container">
      <Heading text={string_table.CATEGORIES_TITLE} as="T28" />
      <div className="categories-body">
        {products?.map((product, pi) => (
          <Fragment key={product.product}>
            {product.category !== products[pi - 1]?.category && (
              <Text text={product.category} textWeight="bold" classNames={['category-label']} />
            )}
            <CategoryProduct product={product} />
            {products[pi + 1] && product.category !== products[pi + 1]?.category && <hr className="category-divider" />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
export default Categories;
