import React, { useEffect, useRef, useState } from 'react';
import { CalculatorContext, CalculatorContextSharedState } from '..';
import getPPLSI from 'contexts/CalculatorContext/getPPLSI';
import { PPLSIPayload } from '@legalshield/frontend-commons';
import useStateFunctions from 'contexts/CalculatorContext/useStateFunctions';

export interface CalculatorContextProviderProps {
  children: React.ReactNode;
}

export const CalculatorContextProvider = (props: CalculatorContextProviderProps) => {
  const { children } = props;

  const [sharedState, setSharedState] = useState<CalculatorContextSharedState>({
    selectedProducts: [],
  });

  const functions = useStateFunctions(setSharedState);

  const _pplsi = useRef<PPLSIPayload | undefined>(getPPLSI());
  const [pplsi] = React.useState<PPLSIPayload | undefined>(_pplsi.current);

  useEffect(() => {
    import('data/products').then((data) => {
      setSharedState((prevState) => ({
        ...prevState,
        products: data.products,
      }));
    });
  }, []);

  return (
    <CalculatorContext.Provider
      value={{
        pplsi,
        ...functions,
        ...sharedState,
      }}
    >
      {children}
    </CalculatorContext.Provider>
  );
};
