import * as React from 'react';
import { CalculatorContextSharedState, Product } from 'contexts/CalculatorContext/types';
import { useCallback } from 'react';

const useStateFunctions = (setSharedState: React.Dispatch<React.SetStateAction<CalculatorContextSharedState>>) => {
  const toggleProduct = useCallback(
    (product: Product) => {
      setSharedState((prevState) => {
        let selectedProducts = prevState.selectedProducts;
        const index = selectedProducts.findIndex((p) => p.product === product.product);
        if (index > -1) {
          selectedProducts = [...selectedProducts.slice(0, index), ...selectedProducts.slice(index + 1)];
        } else {
          selectedProducts = [...selectedProducts, product];
        }

        return {
          ...prevState,
          selectedProducts,
        };
      });
    },
    [setSharedState],
  );

  return {
    toggleProduct,
  };
};
export default useStateFunctions;
