/* istanbul ignore file */
import { GridRow, GridCol, Heading, Image, Text, Grid } from '@legalshield/adonis-ux-framework';
import smartLock from '../../images/smart-lock.svg';
import './index.scss';

const Banner = () => {
  return (
    <Grid classNames={['calculator-content-inner']}>
      <GridRow variant="halves" classNames={['banner-row']}>
        <GridCol>
          <Heading as="T39" text={string_table.BANNER_TITLE} classNames={['banner-text-color']} />
          <Text text={string_table.BANNER_DESCRIPTION} textSize="small" classNames={['banner-text-color pt-4']} />
        </GridCol>
        <GridCol classNames={['banner-image']}>
          <Image alt="calculator" src={smartLock} />
        </GridCol>
      </GridRow>
    </Grid>
  );
};
export default Banner;
