import { Heading, Text, Image } from '@legalshield/adonis-ux-framework';
import './index.scss';
import { useCalculator } from 'contexts/CalculatorContext/useCalculator';
import { useMemo } from 'react';
import priceTag from '../../images/price-tag.svg';
import { formatCurrency } from 'utils';

const Calculator = () => {
  const { selectedProducts } = useCalculator();
  const total = useMemo(() => selectedProducts.reduce((acc, product) => acc + product.price, 0), [selectedProducts]);

  return (
    <div className="calculator-container">
      <Heading text={string_table.CALCULATOR_TITLE} as="T28" />
      <div className="calculator-body">
        <div className="calculator-body-top">
          <Heading text={string_table.CALCULATOR_LEGAL_NEED} as="T16" />
          <Heading text={string_table.CALCULATOR_AVERAGE_COST} as="T16" classNames={['calculator-average-cost']} />
        </div>
        <div className="calculator-body-middle">
          {selectedProducts.map((product) => (
            <div key={product.product} className="calculator-body-middle-product">
              <Text text={product.product} />
              <Text text={formatCurrency(product.price)} />
            </div>
          ))}
        </div>
        <div className="calculator-body-bottom">
          <Heading text={string_table.CALCULATOR_TOTAL + ':'} as="T26" />
          <Heading text={formatCurrency(total)} as="T26" />
        </div>
      </div>
      <div className="calculator-disclaimer">
        <Text text={string_table.CALCULATOR_DISCLAIMER} />
      </div>
    </div>
  );
};
export default Calculator;
