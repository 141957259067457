import { useCalculator } from 'contexts/CalculatorContext/useCalculator';
import { Product } from 'contexts/CalculatorContext';
import './index.scss';
import { useMemo } from 'react';

export interface CategoryProductProps {
  product: Product;
}

const CategoryProduct = (props: CategoryProductProps) => {
  const { product } = props;
  const { selectedProducts, toggleProduct } = useCalculator();

  const selected = useMemo(
    () => selectedProducts.findIndex((p) => p.product === product.product) > -1,
    [selectedProducts, product],
  );

  return (
    <div
      id={`product_${product.englishCategory}_${product.englishProduct}`}
      className={`category-product ${selected ? 'category-product-selected' : ''}`}
      onClick={toggleProduct.bind(null, product)}
    >
      {product.product}
    </div>
  );
};
export default CategoryProduct;
