/* istanbul ignore file */
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { App } from 'components/App';
import initializeAnalytics from 'analytics';
import { CalculatorContextProvider } from 'contexts/CalculatorContext';
import './index.scss';

initializeAnalytics();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <CalculatorContextProvider>
      <App />
    </CalculatorContextProvider>
  </StrictMode>,
);
