import {
  AnalyticServiceVendor,
  AnalyticsOptions,
  TagManagerArgs,
  ThemisAnalytics,
} from '@legalshield/frontend-analytics';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
  }
}

const getGtmVars = (): typeof TagManagerArgs => {
  const host = window.location.hostname;
  const containerId = 'GTM-WGQHM2T';

  if (host.includes('dev-') || host.includes('localhost')) {
    return {
      auth: 'BsczOSsS1yPcqaUDPtRb1Q',
      gtmId: containerId,
      preview: 'env-8',
    };
  } else if (host.includes('uat-')) {
    return {
      auth: '7lgD5KKaBOu58J-KvDq6ig',
      gtmId: containerId,
      preview: 'env-9',
    };
  } else {
    return {
      auth: 'FjXKYpS2Hrs2OWnXthSz3g',
      gtmId: containerId,
      preview: 'env-1',
    };
  }
};

const initializeAnalytics = () => {
  const analyticsConfig: AnalyticsOptions = {
    client: AnalyticServiceVendor.GoogleTagManager,
    config: {
      gtmService: getGtmVars(),
    },
  };
  const themisAnalytics = new ThemisAnalytics(analyticsConfig);

  themisAnalytics.initialize();

  return themisAnalytics;
};

export default initializeAnalytics;
