/* istanbul ignore file */

export const formatCurrency = (amount: number) => {
  const region = 'en-US';
  const currency = 'USD';

  return Intl.NumberFormat(region, {
    currency,
    style: 'currency',
  }).format(amount);
};
