/* istanbul ignore file */
import { Footer, Layout, Grid, GridCol, GridRow, Container, ContainerBgColors } from '@legalshield/adonis-ux-framework';
import Banner from 'components/Banner';
import Categories from 'components/Categories';
import Calculator from 'components/Calculator';
import './index.scss';

export const App = () => {
  return (
    <Layout variant="none" classNames={['app']}>
      <Container classNames={['calculator-banner-container']} background={'var(--p300)' as ContainerBgColors}>
        <Banner />
      </Container>
      <Container classNames={['calculator-content-container']} background={'var(--n200)' as ContainerBgColors}>
        <Grid classNames={['calculator-content-inner']}>
          <GridRow variant="halves" classNames={['calculator-content-row']}>
            <GridCol>
              <Categories />
            </GridCol>
            <GridCol>
              <Calculator />
            </GridCol>
          </GridRow>
        </Grid>
      </Container>
      <Footer classNames={['lsux-layout-padding']} htmlContent={footerHtml} />
    </Layout>
  );
};
